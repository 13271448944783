import { Environment } from '@gea/digital-ui-lib';
import { EnvironmentModel } from './model/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  name: Environment.TEST,
  oAuth: {
    clientId: '0ecee5e7-6698-4c61-b4cb-9406dfe53501',
    authority: 'https://login.portal.uat.gea.com/geaiduat.onmicrosoft.com/b2c_1_signin_signup/',
    authorityDomain: 'login.portal.uat.gea.com',
    postLogoutRedirectUri: 'https://insightpartner.blu-red-care.apps.tst.gea.com',
    redirectUri: 'https://insightpartner.blu-red-care.apps.tst.gea.com',
  },
  baseURL: 'https://api.apps.uat.gea.com/',
  subscriptionKey: '',
  hrt: {
    baseURL: 'https://api-totalcarepartner-test.azure-api.net/frontend/v1/',
    subscriptionKey: 'a6e05676a4774641a7f66c1ebe12bff9',
  },
  portal: {
    baseURL: 'https://portal.uat.gea.com/',
  },
};
